import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { updateLiveInst } from "../../Network/InstApi";
import { ApiUrl } from "../../Network/Urls";

const EditProfile = (props) => {
	const fileInputRef = useRef(null);
	const online = useSelector((state) => state.online.online);
	const User = useSelector((state) => state.auth.user);
	const inst = useSelector((state) => state.inst.activeInst);
	const navigate = useNavigate();

	const [Name, setName] = useState(inst.name);
	const [Higher, setHigher] = useState(inst.higher);
	const [Email, setEmail] = useState(inst.email);
	const [Library, setLibrary] = useState(inst.library);
	const [Contact, setContact] = useState(inst.contact);
	const [Logo, setLogo] = useState(inst.logo);
	const [Motto, setMotto] = useState(inst.motto);
	const [Loading, setLoading] = useState(false);

	useEffect(() => {
		setName(inst.name);
		setEmail(inst.email);
		setContact(inst.contact);
		setLogo(inst.logo);
		setLibrary(inst.library);
		setMotto(inst.motto);
		setHigher(inst.higher);
	}, [inst]);

	//redux dispatch
	const dispatch = useDispatch();

	const HandleSubmit = async (e) => {
		e.preventDefault();
		let linker = Date.now();
		setLoading(true);
		await updateLiveInst(
			{
				...inst,
				name: Name,
				higher: Higher,
				motto: Motto,
				email: Email,
				library: Library,
				logo: Logo,
				contact: Contact,
				trace: linker,
			},
			User.token,
			dispatch
		);
		setLoading(false);
		navigate(`/${Name}`);
	};
	const ConvertImage = () => {
		const fileInput = document.getElementById("fileInput");
		const file = fileInput.files[0];

		const reader = new FileReader();
		reader.onload = function (e) {
			const base64Data = e.target.result;

			let image = new Image();
			image.src = base64Data;

			image.onload = () => {
				let canvas = document.createElement("canvas");
				let ctx = canvas.getContext("2d");
				let MAX_SIZE = 200 * 1024;
				let width = image.width;
				let height = image.height;
				let ratio = 1;

				if (file.size > MAX_SIZE) {
					ratio = Math.sqrt(MAX_SIZE / file.size);
					width *= ratio;
					height *= ratio;
				}
				canvas.width = width;
				canvas.height = height;

				ctx.drawImage(image, 0, 0, width, height);

				let MinBase64 = canvas.toDataURL("image/jpeg", 0.7);
				setLogo(MinBase64);
			};
		};
		reader.readAsDataURL(file);
	};

	return (
		<div className="row justify-content-center">
			<div className="col-md-6">
				<div className="card p-2">
					<div className="card-header">
						<p className="card-title text-center text-primary text-capitalize">
							Edit {inst.name}
						</p>
					</div>
					<Form onSubmit={HandleSubmit}>
						<input
							type="file"
							accept="image/*"
							onChange={() => ConvertImage()}
							style={{ display: "none", width: "0px", height: "0px" }}
							ref={fileInputRef}
							id="fileInput"
						/>
						<div
							className="form-group d-flex justify-content-around"
							onClick={() => {
								if (fileInputRef.current) {
									fileInputRef.current.click();
								}
							}}
							style={{ cursor: "pointer" }}
						>
							<div>
								<button
									type="button"
									className="btn btn-sm btn-info rounded-pill"
								>
									Add Logo
								</button>
							</div>
							<img
								src={
									Logo?.length > 500 ? `${Logo}` : `${ApiUrl}/uploads/${Logo}`
								}
								alt="logo-select"
								className="img-thumbnail"
								style={{ height: 100, width: "auto" }}
							/>
						</div>
						<div className="form-group">
							<label className="mb-2 text-capitalize"> Institution Type:</label>
							<select
								className="rounded form-control"
								onChange={(e) => setHigher(e.target.value)}
								value={parseInt(Higher)}
							>
								<option value={0}>Select Type</option>
								<option value={1}>Higher Education</option>
								<option value={0}>Others</option>
							</select>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2 text-capitalize">Institution Name:</label>
							<input
								className="rounded form-control"
								placeholder={`enter institution name`}
								value={Name}
								onChange={(e) => setName(e.target.value)}
								required
							/>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2">Institution Email:</label>
							<input
								className="rounded form-control"
								placeholder="enter email"
								value={Email}
								onChange={(e) => setEmail(e.target.value)}
								required
								type="email"
							/>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2">Institution Postal Address:</label>
							<textarea
								className="rounded form-control"
								placeholder="enter postal address (optional)"
								value={Library}
								onChange={(e) => setLibrary(e.target.value)}
							/>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2">Institution Contact:</label>
							<input
								className="rounded form-control"
								placeholder="enter contact"
								value={Contact}
								onChange={(e) => setContact(e.target.value)}
								required
							/>
							<hr />
						</div>
						<div className="form-group">
							<label className="mb-2">Institution Motto:</label>
							<input
								className="rounded form-control"
								placeholder="enter motto"
								value={Motto}
								onChange={(e) => setMotto(e.target.value)}
								required
							/>
							<hr />
						</div>
						<div className="d-flex justify-content-around mb-2">
							{Loading ? (
								<span className="spinner-border text-primary"></span>
							) : (
								<Button variant="primary" type="submit">
									Save
								</Button>
							)}
						</div>
					</Form>
				</div>
			</div>
		</div>
	);
};

export default EditProfile;
